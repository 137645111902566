import  React, {useRef, useState} from "react"
import { FaqData } from "../content/faqDataClass"
import NotFound from "./NotFound"


const FaqList = (props) => {
    const faqData = new FaqData(props.keyword || 'essay')
    const starterList = faqData.getData()
    const [list, updateList] = useState(starterList)
    const textInput = useRef(null)
    const refsList = useRef([])
    refsList.current = list.map((element, i) => refsList.current[i] ?? React.createRef())

    const showItem = (elem) => {
        elem.current.classList.toggle('faq-list-item--active')
    }

    const searchAnswer = (title) => {
        const result = starterList.filter(item => item.q.toLowerCase().includes(title.toLowerCase()))
        updateList(result)
    }
    const cleanSearch = () => {
        textInput.current.value = ''
        updateList(starterList)
    }
    return (
        <div className="faq-wrapper">
            <div className="faq-search__wrapper">
                <input
                    ref={textInput}
                    type="text"
                    className="faq-search"
                    onChange={(e)=> searchAnswer(e.target.value)}
                    placeholder="Search"
                />
                {textInput?.current?.value &&
                    <span aria-hidden onClick={() => cleanSearch()} className="faq-search__close"></span>
                }
            </div>
            {list.length > 0 ?
                <ul className="faq-list">
                    {list.map( (item, index) => {
                        return (
                            <li
                                aria-hidden
                                className="faq-list-item"
                                key={index}
                                ref={refsList.current[index]}
                                onClick={() => showItem(refsList.current[index])}
                            >
                                <div className="faq-list-item_header">
                                    <span className="faq-list__title">{item.q}</span>
                                </div>

                                <div className="faq-list-item_answer">
                                    {item.a}
                                </div>
                            </li>
                        )
                    })}
                </ul>
                :
               <NotFound />
            }
        </div>
    )
}

export default FaqList
